import React from 'react'
import { graphql } from 'gatsby'

import ServicePage from '../../../components/ServicePage'
import injectNestedIntlProvider from '../../../components/NestedIntlProvider'

import python from '../../../images/icons/python.svg'
import django from '../../../images/icons/django.svg'
import mysql from '../../../images/icons/mysql.svg'

const Page = ({ data }) => (
  <ServicePage
    code="backend"
    summaryImage={data.summaryImage}
    technologyImages={[django, python, mysql]}
  />
)

export default injectNestedIntlProvider(Page)

export const query = graphql`
  query($language: String!) {
    summaryImage: file(relativePath: { eq: "bck-backend.png" }) {
      ...SummaryImage
    }
    messages: allKeyValue(
      filter: {
        file: { relativeDirectory: { eq: "pages" }, name: { eq: $language } }
        key: {
          regex: "/^(pages\\.backend)|(pages\\.services\\.)/"
        }
      }
    ) {
      edges {
        node {
          key
          value
        }
      }
    }
  }
`
