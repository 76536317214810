import React from 'react'

const TechnologiesImages = ({ title, images, content }) => (
  <div className="technologies">
    <div className="technologies__title">{title}</div>
    <div className="technologies__images">
      {images.map((img, index) => (
        <div className="technologies__img" key={index}>
          <img src={img} />
        </div>
      ))}
    </div>
    <p className="technologies__list">{content}</p>
  </div>
)

export default TechnologiesImages
