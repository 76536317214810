import React from 'react'
import Button from '@material/react-button'
import LinkI18n, { urlFor } from './LinkI18n'
import { injectIntl } from 'react-intl'
import { FormattedMessage } from 'react-intl'
import { navigate } from 'gatsby'

export const ServicesContact = ({ intl }) => {
  const url = urlFor(intl.locale, 'contact')
  return (
    <div className="services-contact">
      <hr className="page__hr" />
      <p>
      <LinkI18n to="services">
        <FormattedMessage id="common.services.more" />
      </LinkI18n>
      </p>

      <Button
        unelevated
        href={url}
        onClick={e => {
          e.preventDefault()
          navigate(url)
        }}
      >
        <FormattedMessage id="common.contact_us_button" />
      </Button>
    </div>
  )
}

export default injectIntl(ServicesContact)
