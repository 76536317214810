import React from 'react'
import { FormattedMessage } from 'react-intl'

import PageSummary from './PageSummary'
import PageLayout from './layout'
import MarkdownBlockFromTranslation from './MarkdownBlock'
import Grid, { Cell } from './Grid'
import ServicesContact from './ServicesContact'
import PageContent from './PageContent'
import TechnologiesImages from './TechnologiesImages'

const ServicePage = ({
  code,
  summaryImage,
  technologyImages,
  isProgramming = true,
}) => {
  return (
    <FormattedMessage id={`pages.${code}.title`}>
      {title => (
        <PageLayout
          title={title}
          pageSummary={
            <PageSummary
              title={<FormattedMessage id={`pages.${code}.title`} />}
              lead={<FormattedMessage id={`pages.${code}.lead`} />}
              imageFluid={summaryImage && summaryImage.childImageSharp.fluid}
            />
          }
          pageClassName="page--services"
        >
          <PageContent>
            <MarkdownBlockFromTranslation id={`pages.${code}.description`} />

            {isProgramming && (
              <p>
                <FormattedMessage id="common.programming.description" />
              </p>
            )}
          </PageContent>

          {technologyImages && (
            <>
              <hr className="page__hr" />
              <Grid>
                <Cell spanStyle="half_sized">
                  <div className="what-we-do">
                    <h6>
                      <FormattedMessage id={`pages.${code}.what_we_do_title`} />
                    </h6>
                    <MarkdownBlockFromTranslation
                      id={`pages.${code}.what_we_do_desc`}
                    />
                  </div>
                </Cell>

                <Cell spanStyle="half_sized">
                  <TechnologiesImages
                    title={
                      <FormattedMessage id={`pages.${code}.technologies`} />
                    }
                    images={technologyImages}
                    content={
                      <FormattedMessage
                        id={`pages.${code}.technologies_desc`}
                      />
                    }
                  />
                </Cell>
              </Grid>
            </>
          )}

          <ServicesContact />
        </PageLayout>
      )}
    </FormattedMessage>
  )
}

export default ServicePage
